import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import ImageReveal from "../reveals/ImageReveal"

const Figure = ({
  className,
  image,
  caption,
  image_width = "container",
  aspectRatio,
}) => {
  return (
    <figure className={className}>
      <ImageReveal>
        <div className="relative overflow-hidden">
          <Image
            image={image}
            aspectRatio={aspectRatio}
            className="w-full h-auto"
          />
        </div>
      </ImageReveal>
      {((caption && caption.length > 0) ||
        image.title?.length > 0 ||
        image.copyright?.length > 0) && (
        <div className={`${image_width === "screen" ? "" : "-mx-grid"}`}>
          <figcaption
            className={`w-full max-w-screen-xl mx-auto mt-4 ${
              image_width === "screen"
                ? "px-grid"
                : image_width === "container"
                ? "px-0"
                : ""
            }`}
          >
            <div className="px-4">
              {caption && caption.length > 0 ? (
                <span className="mr-4">{caption}</span>
              ) : image.title?.length > 0 ? (
                <span className="mr-4">{image.title}</span>
              ) : (
                ""
              )}
              {image.copyright?.length > 0 && (
                <small>© {image.copyright}</small>
              )}
            </div>
          </figcaption>
        </div>
      )}
    </figure>
  )
}

export default Figure
